/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'
import classes from '@src/styles/Pitch.module.css'
import { useRouter } from 'next/router'
import PitchConstants from '@src/constants/pitch'
import { segmentTrack } from '@src/functions/segment'
import classNames from 'classnames'
import openKustomer from '@src/functions/kustomer'
import checkDynamicInsert from '@src/functions/dynamic-insert'
import TrustBox from 'src/pages/common/components/TrustBox'
import { useMediaQuery } from 'react-responsive'
import BookingWidget from './BookingWidget'
import ComplexJob from './ComplexJob'

const urlTaskMapping: any = {
  cardboard_removal: 'cardboard-removal',
  junk_removal: 'junk-removal',
  dumpster_rental: 'dumpster-rental',
  power_washing: 'power-washing',
  "piano-removal": 'junk-removal',
}

const dynamicLabels: any = {
  'junk-removal': 'Waste Removal',
  'cardboard-removal': 'Cardboard Removal',
  'dumpster-rental': 'Dumpster Rental',
}

const HeroText = ({
  isBusiness,
  dynamicText,
  className
}: {
  isBusiness?: boolean
  dynamicText?: string | null
  className?: string
}) => (
  <h1 className={classNames(classes.heroText, className)}>
    {isBusiness ? (
      <>
        Radically
        <br /> Simplify Your
        <br /> Business’
        <br /> Waste Removal
      </>
    ) : (
      dynamicText || (
        <>
          Fast
          <br /> Reliable
          <br /> Sustainable
        </>
      )
    )}
  </h1>
)

const SubText = ({ isBusiness, content, className }: { isBusiness?: boolean, content?: any, className?: string }) => (
  <div className={classNames(classes.subTextContainer, className)}>
    <p className={classes.subText}>
      {isBusiness
        ? 'Sick of expensive, unreliable, inflexible waste management? '
        : content?.subText || 'Simplify your waste management with Dimension. From junk removal to dumpster rentals, we offer a range of customizable services to fit your unique needs and budget.'}
    </p>
    <p className={classes.subText}>
      {isBusiness
        ? 'Dimension solves all of your worries with a single click. '
        : content?.secondarySubText || 'Get a free quote online in minutes, or chat with our team to discuss customized solutions.'}
    </p>
    {!isBusiness && (
      <button className={classes.secondaryButtonDark} onClick={openKustomer}>
        <p>Chat With Us</p>
      </button>
    )}
  </div>
  );

function Pitch({ isBusiness, content }: { isBusiness?: boolean, content?: any }) {
  const router = useRouter()
  const { market, location, dynamic, keyword } = router.query;
  const [dynamicProps, setDynamicProps] = useState<any>({
    valid: false,
    dynamicText: dynamic,
    parameters: {
      market,
      locationId: location,
      dynamic,
    }
  });
  const [isMobile, setIsMobile] = useState(false);
  const mobileQuery = useMediaQuery({ query: '(max-width:768px)'});
  const dynamicLocation = dynamicProps?.address?.city;
  const regexPath: any = router.pathname.match(/(cardboard_removal|junk_removal|dumpster_rental|piano-removal|hazardous_waste)/g);
  const urlTask = urlTaskMapping[regexPath]
  const defaultTask = urlTask ?? PitchConstants.defaultValues[PitchConstants.Input.Task]
  const paramsProps = {
    defaultTask,
    dynamicProps,
    content,
    keyword,
  }

  const fetchDynamic = async () => {
    const props: any = await checkDynamicInsert({
      query: router.query,
      params: router.pathname?.split("/")?.[1],
    });
    const { data } = props
    if (data?.valid) {
      segmentTrack("Location Served", {
        location: data?.address?.location ?? "",
        from: "Dynamic Insert",
        ...(data?.parameters ?? {}),
      });
    } else if (data?.parameters?.locationId || data?.parameters?.market) {
      segmentTrack("Location Not Served", {
        location: data?.address?.location ?? "",
        from: "Dynamic Insert",
        ...(data?.parameters ?? {}),
      });
    }
    setDynamicProps({
      ...data,
      fetched: true,
    });
  };

  useEffect(() => {
    if (!market && !location) return;
    fetchDynamic();
  }, [market, location]);

  useEffect(() => {
    setIsMobile(mobileQuery);
  }, [mobileQuery]);

  const dynamicText = (dynamicLocation ? `${dynamicLocation } ` : "") +
    (dynamic ? `${dynamic}` : regexPath ? (content?.heroText ?? dynamicLabels[defaultTask]) : "");

  return (
    <section className={classes.container}>
      <div className={classes.content}>
        <div className={classes.leftPane}>
          <HeroText isBusiness={isBusiness} dynamicText={dynamicText} className="hide-on-desktop" />
          <BookingWidget isBusiness={isBusiness} paramProps={paramsProps} />
          <SubText isBusiness={isBusiness} content={content} className="hide-on-desktop" />
          {!isBusiness && <ComplexJob />}
          {isMobile && <TrustBox isMobile={isMobile} />}
        </div>
        <div className={classes.rightPane}>
          <div className={classes.pitchContent}>
            <HeroText isBusiness={isBusiness} dynamicText={dynamicText} />
            <SubText isBusiness={isBusiness} content={content} />
            <TrustBox isMobile={isMobile} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Pitch
